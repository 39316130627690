<template>
  <div class="relative" :class="{ [custom]: !!custom }">
    <label v-if="!!label || $slots.label" :for="uid" class="text-13 mb-4 block text-gray-700 dark:text-gray-100">
      <slot name="label">{{ label }}</slot>
    </label>
    <div
      class="rounded-4 text-13 flex items-center border border-gray-200 bg-white px-8 placeholder:text-gray-300 focus-within:border-blue-500 dark:border-gray-600 dark:bg-black placeholder:dark:text-gray-500"
      :class="{
        '!border-red-500': !!hasErrors,
        '!border-gray-200 !bg-gray-50 !text-gray-300 dark:!border-gray-600 dark:!bg-gray-800 dark:!text-gray-500':
          disabled,
        '!border-[--bb-input__border] !bg-[--bb-input__bg] dark:!border-[--bb-input__border--dark] dark:!bg-[--bb-input__bg--dark]':
          custom,
      }"
    >
      <slot name="prefix" />
      <field
        :id="uid"
        v-model="model"
        class="rounded-4 text-13 h-full w-full bg-white py-10 text-black outline-none placeholder:text-gray-300 dark:bg-black dark:text-white placeholder:dark:text-gray-500"
        :class="{
          '!bg-gray-50 !text-gray-300  dark:!bg-gray-800 dark:!text-gray-500': disabled,
          '!bg-[--bb-input__bg] !text-[--bb-input__text] dark:!bg-[--bb-input__bg--dark] dark:!text-[--bb-input__text--dark]':
            custom,
        }"
        :disabled="disabled"
        :name="name"
        :placeholder="placeholder"
        :readonly="readonly"
        :rules="rules"
        :type="type"
        :autocomplete="autocomplete"
      />
      <slot name="suffix" />
    </div>
    <error-message class="text-11 absolute left-0 top-full pt-4 text-red-500" :name="name" />
  </div>
</template>

<script lang="ts" setup generic="T extends HTMLInputElement['type']">
import { Field, ErrorMessage, useField } from 'vee-validate';
import { useId } from 'vue';
import { type StringSchema, type NumberSchema } from 'yup';

const {
  name,
  type = 'text',
  rules = '',
  placeholder = '',
  label = '',
  autocomplete = 'off',

  custom = '',
} = defineProps<{
  // Using HTMLInputElement['autocomplete'] results in 'Expression produces a union type that is too complex to represent'
  autocomplete?: string;
  disabled?: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  readonly?: boolean;
  rules?: NumberSchema | Record<string, unknown> | StringSchema | string;
  type?: T;

  custom?: string;
}>();

// If the type is 'number', and the field is empty or not a number, Vue returns a string. (https://vuejs.org/guide/essentials/forms.html#number)
type ModelValue = T extends 'number' ? number | string : string;

// Typescript does not recognize the 'trim' modifier, but it is still a valid default modifier.
const model = defineModel<ModelValue, 'trim'>({ required: true });

const uid = useId();

const hasErrors = useField(name).errorMessage;
</script>
